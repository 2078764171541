import { createMuiTheme } from '@material-ui/core/styles';

// TODO USE OR DELETE

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1e305e',
    },
    secondary: {
      main: '#7fd6a9',
    },
    error: {
      main: '#1e305e',
    },
    background: {
      default: '#fff',
    },
  },
});

export default theme;