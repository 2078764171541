import React from 'react'
import {
  Route,
  Link
} from "react-router-dom";
import '../Styles/Home.css'
import { Button}  from '@material-ui/core'

const Home = () => {
  return(
    <Route >
      <div className='home-container'>
      <Link to='filteredIngredient' className={'home-button'}>
        <Button color='primary' variant='contained'>
          Filter By Ingredient
        </Button> 
       </Link>  
       <Link to='filteredGlass' className={'home-button'}>
        <Button color='primary' variant='contained'>
          Filter By Glass
          </Button>
        </Link>
      </div>
    </Route>
  )
}

export default Home